<template>
    <default-template
        title="温熱・省エネ設備機器ポータルサイト(住宅版)"
        :is-visible-public="false"
        :is-visible-logout="false"
        :is-visible-pass-update="false"
        :is-visible-jigyosya-login="true"
        :is-house="true"
        :is-visible-breadcrumb="true"
        :breadcrumb-category="'コージェネレーション機器一覧'"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">コージェネレーション機器一覧</h2>
                <app-button
                    name="事業者リンク"
                    @click="showLinks"
                    ref="jest-public-cogenerations-show-links"
                    class="inline"
                />
                <app-button
                    name="機器リストダウンロード"
                    @click="download"
                    ref="jest-public-cogenerations-download"
                    class="inline"
                />
                <hr class="hp_flexspacer"/>
            </div>
            <div>
                <div class="bl_searchBox_outer">
                    <p class="bl_searchBox_outer_msg">検索条件を入力してください。</p>
                    <p class="bl_searchBox_outer_updateDate">最終更新日：{{ lastUpdateDate }}</p>
                    <hr class="hp_flexspacer"/>
                    <div class="bl_searchBox ly_searchBox">
                        <div class="uq_flexcolumn">
                            <div class="bl_input_container">
                                <app-label
                                    name="brand_name"
                                    label="ブランド事業者名"
                                />
                                <app-select
                                    v-model="brand_name"
                                    :label="'選択してください'"
                                    :vmodel-label="brand_name"
                                    ref="jest-public-brand-name"
                                >
                                    <app-select-option
                                        :value="''"
                                        v-model="brand_name"
                                    >
                                        選択してください
                                    </app-select-option>
                                    <app-select-option
                                        v-for="(item, index) in brandNames"
                                        :key="index"
                                        :value="item"
                                        v-model="brand_name"
                                    >
                                        {{item}}
                                    </app-select-option>
                                </app-select>
                            </div>
                            <div class="bl_input_container bl_input_container_column">
                                <app-text
                                    type="date"
                                    name="release_date_from"
                                    ref="jest-release-date-from"
                                    v-model="release_date_from"
                                    label="発売予定年月日"
                                    class="formrow"
                                    unit="以降"
                                />
                                <div class="uq_include_expired">
                                    <input
                                        type="checkbox"
                                        v-model="include_expired"
                                        id="include_expired"
                                        name="include_expired"
                                        ref="jest-include-expired"
                                        class="el_checkbox_input"
                                    />
                                    <label for="include_expired"
                                        :class="{'checked':include_expired}"
                                        class="el_checkbox_label">
                                        販売終了等のパッケージを表示
                                    </label>
                                </div>
                            </div>
                        </div>
                            
                        <div class="bl_input_container bl_input_container_column">
                            <app-text
                                name="pu_number"
                                ref="jest-pu-number"
                                v-model="pu_number"
                                label="発電ユニット番号"
                                class="formrow  wd_20em"
                            />
                            <app-text
                                name="tu_number"
                                ref="jest-tu-number"
                                v-model="tu_number"
                                label="貯湯ユニット品番"
                                class="formrow  wd_20em"
                            />
                            <app-text
                                name="bb_number"
                                v-model="bb_number"
                                label="補助熱源機品番"
                                class="formrow  wd_20em"
                            />
                            <app-text
                                name="certificate_number"
                                v-model="certificate_number"
                                label="成績証明書番号等"
                                class="formrow  wd_20em"
                            />
                        </div>
                        
                        <div class="uq_flex_end">
                            <div>
                                <p class="uq_note">※2021年4月1日より「アイシン精機株式会社」は「株式会社アイシン」に社名変更いたしました。</p>
                                <p class="uq_note">※2021年10月1日より「パナソニック株式会社アプライアンス社」は<br class="indent">「パナソニック株式会社エレクトリックワークス社」に社名変更いたしました。</p>
                            </div>
                            <app-button
                                name="検索"
                                @click="search"
                                ref="jest-public-cogenerations-search"
                                class="inline wd_100px"
                            />
                        </div>
                    </div>
                    <div class="bl_searchBox_outer_info">
                        <h5>機器リストダウンロードについて</h5>
                        <p>機器リストについては、ポータルサイトに登録されている最新の情報を表示したものであり、エネルギー消費性能計算プログラム（住宅版）に反映されていない機器が含まれる場合がございます。予めご了承ください。</p>
                    </div>
                </div>
                <p v-if="total != 0">{{total}}件中{{start}}～{{end}}件表示</p>
                <table class="lists_table">
                    <thead>
                        <tr>
                            <th class="lists_table_brand_name"><span>ブランド</span><span>事業者名</span></th>
                            <th class="lists_table_pu_number"><span>発電ユニット</span><span>番号</span></th>
                            <th class="lists_table_tu_number"><span>貯湯ユニット</span><span>品番※</span></th>
                            <th class="lists_table_bb_number"><span>補助熱源機</span><span>品番</span></th>
                            <th class="lists_table_certificate_number"><span>成績証明書</span><span>番号</span></th>
                            <th class="lists_table_performanceValidationCategory"><span>性能確認</span><span>区分</span></th>
                            <th class="lists_table_releaseYearMonth"><span>発売予定</span><span>年月</span></th>
                            <th><span>販売</span><span>終了等</span></th>
                            <th class="hp_alignLeft"></th>
                        </tr>
                    </thead>
                    <tr
                        v-for="item in cogenerationSystems"
                        :key="'cogenerationSystems' + item.id"
                        :ref="'cogenerationSystems' + item.id"
                    >
                        <td>{{item.brand_name.name}}</td> 
                        <td>{{item.pu_number}}</td> 
                        <td>{{item.tu_number}}</td> 
                        <td>{{item.bb_number}}</td> 
                        <td
                            @click="getFile(item)"
                            :class="{clickable:isNotCanceled(item.status)}"
                            :ref="'cogenerationCertificate' + item.id"
                        >
                            {{item.certificate_number}}
                        </td> 
                        <td>{{performanceValidationCategory(item)}}</td> 
                        <td>{{releaseYearMonth(item.release_date)}}</td> 
                        <td class="hp_alignCenter"><span v-if="item.status == 5">●</span></td> 
                        <td class="relative">
                            <app-button
                                name="コピー"
                                @click="copy(item)"
                                @mouseover="over(item.id)"
                                @mouseleave="leave()"
                                :ref="'jest-public-cogenerations-copy' + item.id"
                                class="inline outlined wd_80px uq_btnCopy__front"
                            />
                            <div
                                class="hover"
                                v-if="hover(item.id)"
                            >
                                <p v-if="!copied" class="hover_explain">品番等コピーとはエネルギー消費性能計算プログラムの検索ボックスに貼り付ける品番などの情報をコピーする機能です</p>
                                <div
                                    class="popup"
                                    v-if="copied"
                                >
                                    <p class="hover_copied">コピーしました。</p>
                                </div>
                            </div>
                            <app-button
                                name="詳細"
                                @click="toDetail(item.id)"
                                :ref="'jest-public-cogenerations-to-detail' + item.id"
                                class="inline wd_80px"
                            />
                        </td> 
                    </tr>
                </table>
                <p>※補助熱源ユニット（貯湯部を含む）品番を含む</p>
                <list-pager
                    :page="page"
                    :page-count="pageCount"
                    @page-change="pageChange"
                />
            </div>
            <app-button
                name="戻る"
                class="cancel"
                @click="back"
                ref="jest-public-links-back"
            />
            <p
                id="copy"
                v-show="false"
            />
            <div
                id="invisible"
                v-show="false"
            />
        </div>
    </default-template>
</template>

<script>
/**
 * P014 コージェネレーション機器一覧
 * 
 * [算出プロパティ]
 * lastUpdateDate...日本語表記の最終更新年月日
 * releaseYearMonth...日本語表記の発売年月
 * hover...指定されたidの位置のコピーボタンがホバーされているか否か
 * performanceValidationCategory...性能確認区分か読み込み遅延時(この箇所だけ起こる)に空白を返す
 * 
 * [イベント処理]
 * copy...クリック箇所に応じたブランド名、発電ユニット番号、貯湯ユニット品番、補助熱源機品番、成績証明書番号のテキストをクリップボードにコピーする
 * closePopup...コピー時に表示されるポップアップを閉じる
 * toDetail...詳細画面への遷移
 * over...mouseoverしたボタンのidをdataに設定
 * leave...mouseoverしたボタンのid情報を削除
 * toLogin...ログイン画面へ遷移
 * back...カテゴリー一覧(住宅版)へ遷移
 */
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import { mapComputedProperties, move, isSetNumber, pageContentCount } from '@/util'
import ListPager from '@/components/molecules/ListPager'
import pdf from "@/mixins/pdf"

export default {
    name:'PublicCogenerations',
    components:{
        ListPager
    },
    data(){
        return {
            copied:false,
            hovered:null
        }
    },
    mixins:[pdf],
    computed:{
        ...mapGetters({
            cogenerationSystems:'cogenerations/cogenerationSystems',
            lastUpdate:'cogenerations/lastUpdate',
            total:'cogenerations/total',
            brandNames:'cogenerations/brandNames',
            data:'cogenerations/searchCogenerationsData'
        }),
        ...mapComputedProperties([
            'brand_name',
            'release_date_from',
            'include_expired',
            'pu_number',
            'tu_number',
            'bb_number',
            'certificate_number',
            'page'
        ]),
        lastUpdateDate(){
            return moment(this.lastUpdate).format('YYYY年MM月DD日')
        },
        releaseYearMonth(){
            return function(date){
                return moment(date).format('YYYY年MM月')
            }
        },
        hover(){
            return function(id) {
                return this.hovered === id
            }
        },
        performanceValidationCategory(){
            return function (item) {
                return item.cogeneration_system_certificate ? item.cogeneration_system_certificate.performance_validation_category : ''
            }
        },
        pageCount(){
            return Math.ceil(this.total / pageContentCount)
        },
        start(){
            return 1 + (this.page - 1) * pageContentCount
        },
        end(){
            const count = this.page * pageContentCount
            return count < this.total ? count : this.total
        }
    },
    async mounted(){
        const loader = this.$loading.show()
        await Promise.all([
            this.searchCogenerations({publicFlag:true, page:this.page}),
            this.getBrandNames()
        ])
        loader.hide()
    },
    methods:{
        ...mapActions({
            searchCogenerations:'cogenerations/search',
            getBrandNames:'cogenerations/getBrandNames',
            csvDownload:'cogenerations/download',
            update:'cogenerations/updateSearchCogenerationsData',
            getDeviceCertificateFile:'cogenerations/getDeviceCertificateFile',
            linksUpdate:'links/update',
        }),
        async search() {
            const loader = this.$loading.show()
            await this.searchCogenerations({page:1, publicFlag:true})
            loader.hide()
        },
        copy(item) {
            // コピーするテキストの作成
            const text = item.brand_name.name.concat(isSetNumber(item.pu_number), isSetNumber(item.tu_number), isSetNumber(item.bb_number), isSetNumber(item.certificate_number))
            // コピーを実行
            this.$copyText(text)
                .then(() => {
                    this.copied = true
                })
        },
        closePopup(){
            this.copied = false
        },
        async download(){
            const loader = this.$loading.show()
            await this.csvDownload()
            loader.hide()
        },
        toDetail(id){
            move(this.$router, this.$route.path + '/' + id)
        },
        over(id){
            this.hovered = id
        },
        leave(){
            this.hovered = null
            this.closePopup()
        },
        back(){
            move(this.$router, '/house/links')
        },
        async pageChange(page){
            const loader = this.$loading.show()
            await this.searchCogenerations({page, publicFlag:true})
            loader.hide()
        },
        showLinks(){
            this.linksUpdate({
                selectedCategoryCode: '1042',
                selectedCategoryName: 'コージェネレーション',
                showingLevel1: '一次エネルギー消費量',
                showingLevel2: '発電設備',
                showingLevel3: 'コージェネレーション'
            })
                .then(() => {
                    move(this.$router, '/house/links/organizations')
                })
        },
        /**
         * 当該機器データに紐づく成績証明書等PDFを別ウィンドウで表示する
         */
        async getFile(item){
            if (this.isNotCanceled(item.status)) {
                const file = await this.getDeviceCertificateFile(item.cogeneration_system_certificate_id)
                this.openPdf(file, 'cogeneration')
            }
        },
        isNotCanceled(status){
            return status !== 5
        },
    }
}
</script>

<style scoped>
.bl_searchBox{
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 760px;
}
.uq_flexcolumn{
    display: flex;
    flex-direction: column;
    margin-right: 16px;
}
.uq_flexcolumn >>> .select_btn{
    min-width: 148px;
}
.uq_flexcolumn >>> .select_wrapper label{
    width: 100%;
}
.uq_flexcolumn >>> label{
    width: 7.5em;
    font-weight: 600;
    font-size: 12px;
}
.uq_flexcolumn >>> p{
    font-weight: 600;
    font-size: 12px;
}
.uq_flex_end{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: fit-content;
    margin-top: 4px;
}
.uq_flex_end>p{
    display:inline-block;
    font-size: 12px;
    padding-right: 16px;
}
.uq_flex_end >>> button.el_button {
    margin: 0 0 0 auto;
}
.bl_searchBox>.bl_input_container:not(:last-of-type){
    margin-right: 0;
    margin-bottom: 0;
}
.bl_searchBox .bl_input_container_column{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.bl_searchBox >>> .select_wrapper div.select_btn{
    width: 19em;
}
p.uq_note{
    font-size:0.75rem;
    display:inline-block;
    width: fit-content;
    min-width: 50%;
    margin: 0 16px 0 1em;
    padding-right: 0;
    text-decoration: underline;
    text-indent:-1em;
}
.bl_searchBox_outer_info{
    max-width: 265px;
    margin-left:  auto;
    padding: 16px 24px;
    font-size: 12px;
    background-color: #f1f4ec;
}
.bl_searchBox_outer_info>h5{
    font-size: 14px;
    margin: 0 0 4px;
}
.bl_searchBox_outer_info>p{
    margin: 0;
}
.uq_include_expired{
    display: flex;
    flex-direction: row;
    margin-top: 16px;
}
.wd_20em{
    width: 20em;
}
.bl_searchBox .input_container.wd_20em >>> label{
    width: 7.5em;
}
.lists_table{
    width: 100%;
}
.hover{
    position: absolute;
    top: -0.5rem;
    right: 168px;
    width: fit-content;
    padding: 16px;
    background-color: #FFF;
    border: 1px solid #000;
    box-shadow: 0 1px 1px 2px rgba(0,0,0, .1);
    font-weight: bolder;
    z-index: 980;
}
.hover p{
    margin: 0;
}
.hover_explain{
    min-width: 20em;
}
.hover_copied{
    white-space: nowrap;
}
.relative{
    width: 168px;
    max-width: 176px;
    position: relative;
    padding: 0 ;
}
.relative>>>button{
    margin: 4px 2px;
}
.uq_btnCopy__front{
    z-index:995;
}
.clickable:hover{
    text-decoration: underline;
    cursor: pointer;
}

 /* タブレット縦よりウインドウ幅が狭いもの */
@media screen and (max-width: 1360px) {
    .relative{
        width: 80px;
        min-width: 80px;
    }
    .hover{
        position: absolute;
        top: -0.5rem;
        right: 86px;
    }
    .bl_searchBox_outer_info{
        max-width: 100%;
        margin-top: 8px;
    }
}
</style>